import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // For getting query parameters
import DOMPurify from 'dompurify'; 

const ProductDetails = () => {
    const [product, setProduct] = useState(null);
    const [products, setProducts] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products`); 
                const data = await response.json();
                setProducts(data);
                const id = new URLSearchParams(location.search).get('id'); // Get the product ID from query params
                const filteredProduct = data.find(item => item.product_id === id); // Filter product by ID
                setProduct(filteredProduct);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, [location.search]); // Fetch products when the location changes

    if (!product) return <div>Loading...</div>; // Show loading state while fetching

    return (
        <div style={{ marginTop:'100px',padding:'20px'}}>
             <h1 
                style={{ textAlign: 'center' }} 
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name) }} // Set product name dangerously
            />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                <img
                    src={product.image}
                    alt={product.name}
                    style={{ width: '60%', height: 'auto', objectFit: 'cover' }}
                />
            </div>
            <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.desc) }} />
        </div>
    );
};

export default ProductDetails;
