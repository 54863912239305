import { useState } from "react";
import emailjs from "emailjs-com";
import React from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";


export const Contact = (props) => {
  const [email, setEmail] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const customPopupStyle = {
      width: '600px',
      minHeight: '200px',
      fontSize: '1.2rem',
    };

    if (!validateEmail(email)) {
      toast.error("Enter a valid email address.");
      return;
    }

    try {
      // await emailjs.send("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", { email }, "YOUR_PUBLIC_KEY");
      Swal.fire({
        title: "Subscribed!",
        text: "You have successfully subscribed to the newsletter.",
        icon: "success",
        confirmButtonText: "Great!",
        willOpen: () => {
          const popup = Swal.getPopup();
          Object.assign(popup.style, customPopupStyle);
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setEmail(''); // Clear email input only after confirmation
        }
      });
    } catch (error) {
      console.log(error.text);
      toast.error("There was an error sending your subscription. Please try again later.");
    }
  };

  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-8">
            <div className="row">
              <div className="section-title">
                <h2>Get In Touch</h2>
              </div>
              {/* Basic Google Map Embed */}
              <div style={{ marginBottom: '25px' }}>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4040.1081088005966!2d77.0658052!3d28.6747606!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d046f2fb4b079%3A0xb8a8ab1ee370b412!2sHariom+Engineering+Works!5e0!3m2!1sen!2sus!4v1636499999999!5m2!1sen!2sus&markers=28.6747606,77.0658052"
                  width="100%"
                  height="400"
                  style={{ border: 0 }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>


              <div style={{
                background: 'linear-gradient(135deg, rgba(100, 180, 230, 1), rgba(30, 120, 190, 1))',
                padding: '15px',
                borderRadius: '5px',
                display: 'flex',
                alignItems: 'center'
              }}>
                <div style={{ flex: '1', color: 'white', fontSize: '16px' }}>
                  Subscribe for the latest Newsletter
                </div>
                <input
                  type="email"
                  name="email"
                  className="form-control"
                  placeholder="Email"
                  required
                  style={{ marginRight: '10px', flex: '2' }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button type="button" className="btn btn-custom btn-lg" onClick={handleSubmit}>
                  Subscribe
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-md-offset-1 contact-info">
            <div className="contact-item">
              <h3>Contact Info</h3>
              <p>
                <span>
                  <i className="fa fa-map-marker"></i> Address
                </span>
                {props.data ? props.data.address : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-phone"></i> Phone
                </span>{" "}
                {props.data ? props.data.phone : "loading"}
                <br />
                {props.data ? props.data.phone2 : "loading"}
              </p>
            </div>
            <div className="contact-item">
              <p>
                <span>
                  <i className="fa fa-envelope-o"></i> Email
                </span>{" "}
                {props.data ? props.data.email : "loading"}
              </p>
            </div>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a href={props.data ? props.data.twitter : "/"}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.facebook : "/"}>
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : "/"}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>&copy; 2024 Copyright reserved.</p>
        </div>
      </div>
    </div>
  );
};
