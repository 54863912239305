import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import DOMPurify from 'dompurify'; 

const Products = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await fetch(`https://lpodw9qjh7.execute-api.eu-north-1.amazonaws.com/production/products`); 
                const data = await response.json();
                setProducts(data); 
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    // Function to handle navigation to product details
    const handleView = (product_id) => {
        navigate(`/product-details?id=${product_id}`); 
    };

    return (
        <div style={{ paddingTop: '80px', marginBottom: '20px' }}>
            <h1 style={{ textAlign: 'center' }}>Welcome to HEW Products</h1>
            <p style={{ textAlign: 'center' }}>Here's a list of equipment that we manufacture at HEW.</p>

            <div style={{
                backgroundColor: 'rgb(225, 237, 252)',
                padding: '10px',
                minHeight: 'calc(100vh - 100px)', // Adjust for the header and navbar height
                display: 'flex',
                flexDirection: 'column',
                justifyContent: products.length > 0 ? 'flex-start' : 'center',
                alignItems: 'center',
            }}>
                {products.length > 0 ? (
                    products.map((product, index) => (
                        <div
                            key={index}
                            className="product-container"
                            style={{
                                display: 'flex',
                                flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
                                marginBottom: '60px',
                                height: '350px', // Fixed height for uniform rows
                            }}
                        >
                            <div className="product-image" style={{ flex: 1, marginRight: '10px', marginLeft: '10px' }}>
                                <img
                                    src={product.image}
                                    alt={product.name}
                                    className="zoom-image"
                                    style={{ width: '100%', height: '100%', objectFit: 'cover' }} // Reduced width
                                />
                            </div>
                            <div className="product-details" style={{ flex: 1, padding: '20px', display: 'flex', flexDirection: 'column' }}>
                                <div style={{  }}>
                                    <h2 dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.name) }} />
                                    <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(product.desc) }} />
                                </div>
                                <button
                                    onClick={() => handleView(product?.product_id)} 
                                    style={{
                                        alignSelf: 'flex-start', 
                                        marginTop: '20px',
                                        padding: '10px 15px',
                                        backgroundColor: '#fff',
                                        color: 'black',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background-color 0.3s, transform 0.3s', // Transition for hover effects
                                    }}
                                    onMouseEnter={(e) => {
                                        e.currentTarget.style.backgroundColor = '#f0f0f0'; 
                                    }}
                                    onMouseLeave={(e) => {
                                        e.currentTarget.style.backgroundColor = '#fff'; 
                                    }}
                                >
                                    <i className="fa fa-eye" style={{ marginRight: '8px' }}></i> {/* Eye icon */}
                                    View this product
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                        No products listed yet.
                    </div>
                )}
            </div>

            <style jsx>{`
                .product-container {
                    margin-bottom: 20px;
                }
        
                .product-image img {
                    max-width: 100%; /* Responsive image */
                    height: auto;
                    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
                }
        
                .product-image img:hover {
                    transform: scale(1.05); /* Scale the image up by 5% on hover */
                }
        
                .product-details {
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                }
            `}</style>
        </div>
    );
};

export default Products;
