import React from 'react';

const Licenses = () => {
  return (
    <div style={{marginTop:'100px',padding:'20px'}}>
      <h1>Licenses</h1>
      <p>Details about certifications.</p>
    </div>
  );
};

export default Licenses;
